@media only screen and (max-width: 768px) {


}

@font-face {
  font-family: "united-sans";
  src: local("united-sans"), url(/fonts/UnitedSansRgMd.otf) format("otf");
}

html,
body {
  margin: 0;
  background: #555960;
  font-family: acumin-pro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}
body {
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 106px;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  position: fixed;
}

.signup-btn {
  float: right;
  position: relative;
  width: 125px;
  height: 50px;
  background: #8e6f3e 0% 0% no-repeat padding-box;
  opacity: 1;
  right: 20px;
  outline: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  bottom: 15px;
}

.signup-txt {
  text-align: center;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.title {
  margin: 0;
  text-align: center;
  font-family: acumin-pro, sans-serif;
  font-weight: 700;
  font-style: italic;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 32px;
  text-shadow: 0px 0px 10px #000000c3;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 140px;
}

.txt {
  font-family: acumin-pro, sans-serif;
  letter-spacing: 0px;
  text-shadow: 0px 0px 10px #000000c3;
  color: #ffffff;
  opacity: 1;
  font-weight: bold;
}

li {
  font-family: acumin-pro, sans-serif;
  letter-spacing: 0px;
  text-shadow: 0px 0px 10px #000000c3;
  color: #ffffff;
  opacity: 1;
  font-weight: bold;
}

.form-heading {
  font-stretch: extra-condensed;

  text-align: center;
  font-weight: bold;
  font-family: acumin-pro, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  font-size: 20px;
  text-shadow: 0px 0px 10px #000000c3;
}

.txtInput {
  background: #ffffff;
  border: 1px solid #c4bfc0;
  opacity: 1;
  padding: 15px;
  margin: 10px;
  outline: none;
  display: block;
  width: 100%;
}

.txtarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c4bfc0;
  opacity: 1;
  padding: 15px;
  margin: 10px;
  outline: none;
  width: 100%;
  max-width: 750px;
  font-family: acumin-pro;
  height: 200px;
}

.slect {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c4bfc0;
  opacity: 1;
  padding: 15px;
  margin: 10px;
  width: 100%;
}

.layer {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 0;
}

.signUp {
  background: #8e6f3e 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 20px;
  padding-left: 60px;
  padding-right: 60px;
  outline: none;
  border: none;
  color: #fff;
  font-weight: bold;
  margin-top: 10px;
  font-size: 16px;
  cursor: pointer;
}

.signUp:hover {
  background: #000;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input[type="select"],
input[type="textbox"],
select:focus,
textarea {
  font-size: 16px;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  font-size: 16px;
}
